<template>
  <div class="analyse">
    <van-collapse v-model="activeName" accordion>
      <van-collapse-item title="筛选条件" class="filter" name="1">
        <van-cell title="按设备编号查" :value="filter.devInfo.value" is-link @click="filter.devInfo.showPicker = true"/>
        <van-cell
          title="请选择开始时间"
          :value="filter.startVal.toString()"
          is-link
          @click="filter.startShow = true"
        />
        <van-cell
          title="请选择结束时间"
          :value="filter.endVal"
          is-link
          @click="filter.endShow = true"
        />
        <van-row class="text-center">
          <van-col span="12"
            ><van-button type="primary" size="small" @click="resetData"
              >重置</van-button
            ></van-col
          >
          <van-col span="12">
            <van-button type="primary" size="small" @click="filterData"
              >筛选</van-button
            ></van-col
          >
        </van-row>
      </van-collapse-item>
    </van-collapse>
    <div class="nav-char-custom">
      <div v-for="(item,index) in navlist" :key="index" class="wid_3">
          <div :class="{'block': true,'active': active == index ? true : false}" @click="selectTime(index)"><i :class="item.className"></i>{{ item.title }}</div>
      </div>
    </div>
    <div :class="'glo-content ' + (noticeShow ? 'glo-magbot' : 'glo-magbot0')">
      <div class="nav-char">
        <div class="wid_3">
          <div class="block active">
            {{ currentScene === 'water' ? '测量水深变化曲线' : currentScene === 'range' ? '测量距离变化曲线' :'测量水位和水深变化曲线' }}
          </div>
        </div>
      </div>
      <div>
        <div v-if="loading">
          <van-loading type="spinner" vertical>加载中</van-loading>
        </div>
        <div v-else>
          <div><canvas id="chartWater"></canvas></div>
        </div>
      </div>
      <!-- <div class="nav-char">
        <div class="wid_3">
          <div class="block active">
            设备电压变化曲线
          </div>
        </div>
      </div>
      <div>
        <div v-if="loading">
          <van-loading type="spinner" vertical>加载中</van-loading>
        </div>
        <div v-else>
          <div><canvas id="chartBatVol"></canvas></div>
        </div>
      </div> -->
      
      <tabbar />
    </div>
     <!-- 筛选设备 -->
    <van-popup v-model="filter.devInfo.showPicker" round position="bottom">
        <van-picker show-toolbar :columns="filter.devInfo.columns" @cancel="filter.devInfo.showPicker = false" @confirm="deviceConfirm" />
    </van-popup>
    <!-- 筛选时间段 -->
    <van-popup v-model="filter.startShow" round position="bottom">
      <van-datetime-picker
        v-model="filter.start"
        type="datetime"
        title="选择开始时间"
        :filter="filterOption"
        @confirm="confromStartTime"
        @cancel="filter.startShow = false"
      />
    </van-popup>
    <van-popup v-model="filter.endShow" round position="bottom">
      <van-datetime-picker
        v-model="filter.end"
        type="datetime"
        title="选择结束时间"
        :filter="filterOption"
        @confirm="confromEndTime"
        @cancel="filter.endShow = false"
      />
    </van-popup>
  </div>
</template>

<script>
const F2 = require("@antv/f2");
import tabbar from "@/components/wlms/tabbar.vue";
import moment from "moment";
export default {
  components: {
    tabbar,
  },
  props: ["magbot"],
  data() {
    return {
      active:0,
      chartDataWater: [],
      // chartDataBatVol: [],
      bridgeCode: "",
      loading: false,
      activeName: "1",
      filter: {
        devInfo:{
          devCode:'',
          value:'',
          showPicker:false,
          columns:[]
        },
        start: new Date(),
        end: new Date(),
        startVal: "",
        endVal: "",
        startShow: false,
        endShow: false,
        searchType:'day',
      },

      currentScene:'',
      navlist:[
        {className:'fa fa-line-chart',title:'日视图'},
        {className:'fa fa-line-chart',title:'周视图'},
        {className:'fa fa-line-chart',title:'月视图'}
      ],
      chart0:null,
      // chart1:null
    };
  },
  watch: {
    magbot: function (val) {
      this.magbotVal = val;
    },
  },
  methods: {
    // 筛选设备确认
    deviceConfirm(v){
      this.filter.devInfo.showPicker = false;
      this.filter.devInfo.value = v.text;
      this.filter.devInfo.devCode = v.devCode;
    },
    // 点击切换绘制
    selectTime(index){
      this.loading = true;
      this.chart0.clear(); // 清除
      // this.chart1.clear(); // 清除
      this.active = index;
      if(this.active == 0){
        this.filter.searchType = 'day';
      }else if(this.active == 1){
        this.filter.searchType = 'week';
      }else if(this.active == 2){
        this.filter.searchType = 'month';
      }
      this.getChartData();
    },
    getChartData(){
      return new Promise(resolve => {
        this.$api.WLMS.wlmsChartsData({
          projectCode:this.projectCode,
          devCode:this.filter.devInfo.devCode,
          beginTime:this.filter.startVal || void 0,
          endTime:this.filter.endVal || void 0,
          searchType:this.filter.startVal || this.filter.endVal ? void 0 : this.filter.searchType
        }).then( d => {
          this.currentScene = d[0].sceneType;
          let arrWater = [];
          // let arrBatVol = [];
          for(let i=0;i<d.length;i++){
              d[i].time.forEach((e,index)=>{
                  if(d[i].level.length > 0){
                    arrWater.push({
                      date:e.substring(0,e.lastIndexOf(':')),
                      value:parseFloat(d[i].level[index]),
                      name:'相对水位'
                    });
                  }if(d[i].absLevel.length > 0){
                    arrWater.push({
                      date:e.substring(0,e.lastIndexOf(':')),
                      value:parseFloat(d[i].absLevel[index]),
                      name:'绝对水位'
                    });
                  }
                  if(d[i].deep.length > 0){
                    arrWater.push({
                      date:e.substring(0,e.lastIndexOf(':')),
                      value:parseFloat(d[i].deep[index]),
                      name:this.currentScene === 'range' ? '距离' :'水深'
                    });
                  }
                  // arrBatVol.push({
                  //   date:e.substring(0,e.lastIndexOf(':')),
                  //   value:parseFloat(d[i].batVol[index]),
                  //   name:d[i].devName
                  // });
              })
          }
          this.chartDataWater = arrWater;
          // this.chartDataBatVol = arrBatVol;
          this.loading = false;
          this.chartShow();
          resolve();
        })
      })
    },
    // 绘制图表
    chartShow() {
      this.$nextTick(() => {
        // 水位变化
        this.chart0 = new F2.Chart({
          id: "chartWater",
          pixelRatio: window.devicePixelRatio,
        });
        this.chart0.source(this.chartDataWater);
        this.chart0.scale("date", {
          type: "timeCat",
          mask: "MM-DD HH:mm",
          tickCount: 4,
          range: [0, 1],
        });
        this.chart0.scale("value", {
          tickCount: 6,
        });
        this.chart0.axis("date", {
          label: function label(text, index, total) {
            const textCfg = {};
            if (index === 0) {
              textCfg.textAlign = "left";
            } else if (index === total - 1) {
              textCfg.textAlign = "right";
            }
            return textCfg;
          },
        });
        this.chart0.tooltip({
          custom: true, // 自定义 tooltip 内容框
          onChange: (obj) => {
            const legend = this.chart0.get('legendController').legends.top[0];
            const tooltipItems = obj.items;
            const legendItems = legend.items;
            const map = {};
            legendItems.forEach((item)=>{
              map[item.name] = item;
            });
            tooltipItems.forEach((item)=> {
              const name = item.name;
              const value = item.value;
              if (map[name]) {
                this.currentScene === 'water' ? map[name].value = value + 'cm' :map[name].value = value + 'm';
              }
            });
            legend.setItems(Object.values(map));
          },
          onHide:() => {
            const legend = this.chart0.get('legendController').legends.top[0];
            legend.setItems(this.chart0.getLegendItems().country);
          }
        });
        this.chart0
          .line({
            connectNulls: true, // 将空数据连接
          })
          .shape("smooth")
          .position("date*value").color('name')
        this.chart0.render();

        // 电压变化
        // this.chart1 = new F2.Chart({
        //   id: "chartBatVol",
        //   pixelRatio: window.devicePixelRatio,
        // });
        // this.chart1.source(this.chartDataBatVol);
        // this.chart1.scale("date", {
        //   type: "timeCat",
        //   mask: "MM-DD HH:mm",
        //   tickCount: 4,
        //   range: [0, 1],
        // });
        // this.chart1.scale("value", {
        //   tickCount: 6,
        // });
        // this.chart1.axis("date", {
        //   label: function label(text, index, total) {
        //     const textCfg = {};
        //     if (index === 0) {
        //       textCfg.textAlign = "left";
        //     } else if (index === total - 1) {
        //       textCfg.textAlign = "right";
        //     }
        //     return textCfg;
        //   },
        // });
        // this.chart1.tooltip({
        //   custom: true,
        //   showXTip: true,
        //   showYTip: true,
        //   snap: true,
        //   crosshairsType: "xy",
        //   crosshairsStyle: {
        //     lineDash: [2],
        //   },
        //   onShow: (ev) => {
        //     const items = ev.items;
        //     items[0].name = null;
        //    items[0].value = items[0].value + ' mV';
        //   }
        // });
        // this.chart1
        //   .line({
        //     connectNulls: true, // 将空数据连接
        //   })
        //   .shape("smooth")
        //   .position("date*value").color('#15bfc7')
        // this.chart1.render();
      });
    },
    // 时间选项过滤
    filterOption(type, options) {
      if (type === 'minute') {
        return options.filter((option) => option % 10 === 0);
      }
      return options;
    },
    // 确认开始时间
    confromStartTime(value) {
      this.filter.startVal = moment(value).format("yyyy-MM-DD HH:mm:ss");
      this.filter.startShow = false;
    },
    // 确认结束时间
    confromEndTime(value) {
      this.filter.endVal = moment(value).format("yyyy-MM-DD HH:mm:ss");
      this.filter.endShow = false;
    },
    // 重置
    resetData() {
      this.filter = {
        devInfo:{
          devCode:'',
          value:'',
          showPicker:false,
          columns:this.filter.devInfo.columns
        },
        start: new Date(),
        end: new Date(),
        startVal: "",
        endVal: "",
        startShow: false,
        endShow: false,
        searchType:'day',
      };
    },
    // 筛选
    filterData() {
      if(!this.filter.devInfo.value){
        return this.$toast('请选择设备');
      }
      this.loading = true;
      this.activeName = "";
      this.active = null;
      this.getChartData();
    },
    // 获取设备列表
    devList(){
      return new Promise((resolve)=>{
        this.$api.WLMS.deviceList({projectCode:this.projectCode}).then( d =>{
          this.filter.devInfo.columns = d;
          this.filter.devInfo.columns.forEach( e =>{
            e.text = e.devName;
          });
          this.deviceConfirm(d[0]);
          resolve();
        })
      })
    },
  },
  computed: {
    noticeShow() {
      return window.sessionStorage.getItem("magbot") == "1" && this.magbot != 1
        ? true
        : false;
    },
  },
  mounted() {
    this.projectCode = window.sessionStorage.getItem("projectCode");
    this.devList().then( () => {
      this.getChartData();
    })
  },
};
</script>

<style lang="scss" scoped>
.glo-content {
  padding: 0 4% 4% 4%;
}
.analyse {
  text-align: left;
  .text-center {
    text-align: center;
    button {
      padding: 0 20px;
      margin-top: 5%;
    }
  }
}
.nav-char {
  text-align: center;
  padding: 10px 5px 0 5px;
  display: flex;
  justify-content: space-around;
  .fa {
    padding-right: 4px;
  }
  .wid_3 {
    width: 100%;
  }
  .block {
    line-height: 2.2;
    padding: 0 2%;
    border-radius: 10px;
    color: #585858;
    box-shadow: 1px 1px 5px #dadada;
    font-weight: bold;
  }
  .active {
    background: #15bfc7;
    color: whitesmoke;
    font-size: .85rem;
    letter-spacing: 2px;
  }
}
.nav-char-custom{
  padding: 4%;
  display: flex;
  justify-content: space-around;
  .fa{padding-right: 4px;}
  .wid_3{width: 30%;}
  .block{
    line-height: 2;
    padding: 2% 4%;
    border-radius: 10px;
    color: #585858;
    box-shadow: 1px 1px 5px #dadada;
    font-weight: bold;
    margin-right: 2%;
    text-align: center;
    font-size: 0.9rem;
  }
  .active{
    background:linear-gradient(#99d2e6 0%, #2fd4bd 85%);
    color: whitesmoke;
  }
}
#chartWater,
#chartBatVol{
  width: 100%;
  height: 25rem;
}
.title {
  text-align: left;
  font-size: 0.95rem;
  line-height: 2;
  padding-left: 4%;
  .fa {
    color: #1989fa;
    font-size: 1.2rem;
    padding-right: 2%;
  }
}
</style>